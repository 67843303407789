/* @flow */

import * as React from 'react'
import { PayCallbackTemplate } from '../templates'

import { getQueryVariable } from '../utils'

type Props = {
  location: {
    href: string,
    search: string,
  },
}

export default function PayTransactionSuccess(props: Props) {
  const { location } = props
  const shopName = getQueryVariable(location, 'shop_name') || 'Votre commerçant'
  const shopLogo = getQueryVariable(location, 'shop_logo')
  return (
    <PayCallbackTemplate
      url={location.href}
      name="Wino"
      logo={shopLogo}
      title="Votre paiement a été enregistré"
      description={`${shopName} vous remercie pour cet achat et votre confiance. À très bientôt !`}
      brandText="La caisse enregistreuse de votre commerçant*"
      brandInformations="*Wino est le service choisi par votre commerçant pour les procédures de paiement en ligne de vos achats.  La plateforme garantit des paiements entièrement sécurisés."
    />
  )
}
